import { Box, Link as ChakraLink, HStack, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { newTabs } from 'layout/routeTabs'
import { Fragment, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const DASHBOARD = 'Dashboard'

const SideBarList = ({ tabs, isExpanded }) => {
	const fontColorActive = useColorModeValue('#343434', '#FFFFFF')
	const fontColorPassive = useColorModeValue('#545454', '#898989')
	const ellipseColorPassive = useColorModeValue('#34343433', '#898989')
	const { colorMode } = useColorMode()
	const { pathname } = useLocation()
	const initialTab = newTabs.find((tab) => tab.children?.some((child) => pathname.includes(child.path)))?.label
	const [expandedTab, setExpandedTab] = useState(initialTab ?? DASHBOARD)

	const isDarkMode = colorMode === 'dark'

	return tabs.map((tab) => (
		<Fragment key={tab.label}>
			{isExpanded && (
				<Box>
					<ChakraLink
						onClick={() => setExpandedTab(tab.label)}
						as={NavLink}
						to={tab.children ? tab.children[0].path : tab.path}
						display='flex'
						alignItems='center'
						justifyContent='space-between'
						gap='3'
						_hover={{ textDecoration: 'none' }}
						py={2.5}
						px={3}
						paddingLeft={!tab.isParent && '12'}
						style={({ isActive }) => ({
							borderLeft: `3px solid ${isActive && !tab.isParent ? fontColorActive : 'transparent'}`,
							color: isActive || tab.isParent ? fontColorActive : fontColorPassive,
							fontWeight: isActive && !tab.isParent ? 500 : 400,
						})}>
						<HStack gap={3}>
							{tab.isParent ? tab.icon({ isDarkMode, isActive: tab.label === expandedTab }) : <tab.icon boxSize='5' />}
							<Text fontSize='sm'>{tab.label}</Text>
						</HStack>
						{tab.isParent && (
							<Text
								height={tab.label === expandedTab ? '8px' : '6px'}
								width={tab.label === expandedTab ? '8px' : '6px'}
								bgColor={tab.label === expandedTab ? fontColorActive : ellipseColorPassive}
								rounded='full'
							/>
						)}
					</ChakraLink>
					{tab.children && tab.label === expandedTab && <SideBarList tabs={tab.children} isExpanded={isExpanded} />}
				</Box>
			)}
			{!isExpanded && (
				<ChakraLink
					onClick={() => setExpandedTab(tab.label)}
					as={NavLink}
					to={tab.children ? tab.children[0].path : tab.path}
					display='block'
					py={2.5}
					px={3}
					mx='3px'>
					{tab.isParent ? tab.icon({ isDarkMode, isActive: tab.label === expandedTab }) : <tab.icon boxSize='5' />}
				</ChakraLink>
			)}
		</Fragment>
	))
}

export default SideBarList
