import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ThemeColors } from 'components/chakraComponents/ThemeColors'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle({
	tablist: {
		_light: {
			backgroundColor: ThemeColors.WHITE,
		},
		_dark: {
			backgroundColor: ThemeColors.BACKGROUND_PRIMARY_DARK,
		},
		rounded: 'xl',
	},
	tab: {
		m: 2,
		rounded: 'xl',
		_selected: {
			_light: {
				backgroundColor: ThemeColors.GRAY_950,
				color: ThemeColors.WHITE,
			},
			_dark: {
				backgroundColor: ThemeColors.WHITE,
				color: ThemeColors.BLACK_ALPHA900,
			},
		},
	},
	tabpanel: {
		px: 0,
	},
})

export const tabsTheme = defineMultiStyleConfig({ baseStyle, defaultProps: { variant: 'unstyled', size: 'sm' } })
