export const fetchMethods = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
}

export const pageTitles = {
	DASHBOARD: 'MDM - Dashboard',
	USERS: 'MDM - Users',
	USER_PROFILE: 'MDM - Profile',
	PARTNERS: 'MDM - Company',
	PARTNER_DETAILS: 'MDM - Company Details',
	HOSPITALS: 'MDM - Hospitals',
	DEVICES: 'MDM - Devices',
	DEVICE_DETAILS: 'MDM - Device Details',
	DEVICE_DIAGNOSTICS: 'MDM - Device Diagnostics',
	DEVIGE_LOGS: 'MDM - Device Logs',
	OS_DEPLOYMENTS: 'MDM - OS Deployments',
	OS_CREATE_DEPLOYMENT: 'MDM - Create Deployment',
	OS_DEPLOYMENTS_DETAILS: 'MDM - OS Deployment Details',
	DEVICE_FLAVOUR: 'MDM - Device Flavor',
	DEVICE_APPS: 'MDM - Device APPS',
	DEVICE_APPS_DETAILS: 'MDM - Device APP Details',
	PROFILES: 'MDM - Profiles',
	PROFILE_DETAILS: 'MDM - Profile Details',
	CREATE_PROFILE: 'MDM - Create Profile',
	EDIT_PROFILE: 'MDM - Edit Profile',
	HOSPITAL_FLOORS: 'MDM - Hospital Floors',
	OS_RELEASES: 'MDM - OS Releases',
	APP_RELEASES: 'MDM - APP Releases',
	UPLOAD_ML_MODELS: 'MDM - Upload ML Models',
	CREATE_ML_MODELS: 'MDM - Create ML Models',
	CREATE_TEAM_CONFIG: 'MDM - Create Team Config',
	ML_DEPLOYMENTS: 'MDM - ML Deployments',
	APP_DEPLOYMENTS: 'MDM - APP Deployments',
	APP_DEPLOYMENTS_DETAILS: 'APP Deployment Details',
	CREATE_APP_DEPLOYMENT: 'MDM - Create APP Deployment',
}

export const userRoles = {
	SUPER_USER: 'hac.admin',
	DEVELOPER: 'hac.developer',
	SUPPORT: 'hac.support',
	PARTNER: 'hac.partner',
	HOSPITAL: 'hac.client',
	ADMIN: 'Admin',
	USER_PARTNER: 'Partner',
	CLIENT: 'Client',
}

export const navigationRoutes = {
	DASHBOARD: '/',
	USERS: '/users',
	PARTNERS: '/partners',
	HOSPITALS: '/hospitals',
	DEVICES: '/devices',
	DEVICE_LOGS: '/devicelogs',
	DEVICES_UPLOAD: '/devicesupload',
	PROFILES: '/profiles',
	HOSPITAL_FLOORS: '/hospital-floors',
	OS_DEPLOYMENTS: '/osdeployments',
	APP_DEPLOYMENTS: '/appdeployments',
	FLAVOUR: '/device-flavour',
	APPS: '/device-apps',
	OS_RELEASES: '/os-releases',
	APP_RELEASES: '/app-releases',
}

//Device
export const deviceTable = {
	deviceList: 'deviceList',
}

export const deviceDetailsCommands = {
	activeOfflineCommands: 'activeOfflineCommands',
}

export const deviceLogs = {
	allowList: 'allowList',
	commandLogs: 'commandLogs',
	liveConferences: 'liveConferences',
	pastConferenes: 'pastConferences',
	statusLogs: 'statusLogs',
	receivedLogs: 'receivedLogs',
	deviceLogsList: 'deviceLogsList',
	deviceLogsType: 'deviceLogsType',
}

export const deviceDetails = {
	deviceDetails: 'deviceDetails',
	treeView: 'treeView',
}

export const dashboard = {
	dashboardList: 'dashboardList',
}

//Users

export const usersTable = {
	usersList: 'usersList',
}

//Partners
export const partnersTable = {
	partnersList: 'partnersList',
}

export const partnerDetails = {
	partnerDetails: 'partnerDetails',
	partnersHospitals: 'partnersHospitals',
	mainOrganization: 'mainOrganization',
}

//Hospitals

export const hospitalsTable = {
	hospitalsList: 'hospitalsList',
}

export const hospitalsFLoorsTable = {
	hospitalFloors: 'hospitalFloors',
	hospitalPartnerFloors: 'hospitalPartnerFloors',
}

//OsDeployments

export const osDeploymentsTable = {
	osDeploymentsList: 'osDeploymentsList',
}

//DeviceFlavours

export const deviceFlavoursTable = {
	deviceFlavoursList: 'deviceFlavoursList',
}

export const deviceFlavourDetails = {
	deviceFlavourDetails: 'deviceFlavourDetails',
	deviceFlavourApps: 'deviceFlavourApps',
}

//DeviceApps

export const deviceAppsTable = {
	deviceAppsList: 'deviceAppsList',
}
export const deviceAppsDetails = {
	deviceAppsDetails: 'deviceAppsDetails',
	soloDeviceApp: 'soloDeviceApp',
}

//Profiles

export const profilesTable = {
	profilesList: 'profilesList',
}

export const profilesDetails = {
	profilesDetails: 'profilesDetails',
}

export const teamTypeId = {
	4: 'Health System',
	5: 'Region',
	6: 'Hospital',
	7: 'Department',
	8: 'Floor',
}

export const configurationTypes = {
	1: 'Wifi Band',
	2: 'Ntp',
	3: 'Ml Model',
	4: 'Bandwith',
}

export const deviceDeploymentStatus = {
	0: 'Open',
	1: 'Deploying',
	2: 'Deployed',
	3: 'Failed',
	4: 'Cancelled',
	5: 'Ignored',
	6: 'Check Update',
	7: 'Download Started',
	8: 'Download Failed',
	9: 'Download Finished',
	10: 'Download Stop',
	11: 'Install Started',
	12: 'Install Failed',
	13: 'Install Finished',
	14: 'Deployment Cancelled',
}

export const deviceDeploymentStatusColors = {
	0: '#08C4B0',
	1: '#D5900F',
	2: '#66D50F',
	3: '#FF0000',
	4: '#FF0000',
	5: '#FF0000',
	6: '#08C4B0',
	7: '#08C4B0',
	8: '#FF0000',
	9: '#66D50F',
	10: '#FF0000',
	11: '#66D50F',
	12: '#FF0000',
	13: '#08C4B0',
	14: '#FF0000',
}

export const deploymentJobStatus = {
	1: 'Cancelled',
	2: 'Pending',
	3: 'In Progress',
	4: 'Deployed',
	5: 'Failed',
	6: 'Partially Deployed',
}

export const deploymentJobStatusId = {
	CANCELLED: 1,
	PENDING: 2,
	IN_PROGRESS: 3,
	DEPLOYED: 4,
	FAILED: 5,
	PARTIALLY_DEPLOYED: 6,
}

export const osDeploymentColorSchemes = {
	Cancelled: 'red',
	Pending: 'gray',
	Deployed: 'green',
	Failed: 'orange',
	'In Progress': 'linkedin',
	'Partially Deployed': 'teal',
}

export const appDeploymentColorSchemes = {
	0: 'gray',
	1: 'linkedin',
	2: 'green',
	3: 'orange',
}

export const appDeploymentGroupStatus = {
	0: 'Open',
	1: 'Deploying',
	2: 'Deployed',
	3: 'Partially Deployed',
	4: 'Failed',
	5: 'Cancelled',
}

export const appDeploymentGroupStatusId = {
	OPEN: 0,
	DEPLOYING: 1,
	DEPLOYED: 2,
	PARTIALLY_DEPLOYED: 3,
	FAILED: 4,
	CANCELLED: 5,
}

export const appDeploymentGroupStatusColors = {
	0: '#08C4B0',
	1: '#D5900F',
	2: '#66D50F',
	3: '#08C4B0',
	4: '#FF0000',
	5: '#FF0000',
}

export const companyType = {
	STANDALONE: 'StandaloneCompany',
	MAIN: 'MainCompany',
	SUB: 'SubCompany',
}

export const deviceDeploymentLogs = {
	0: 'OPEN',
	1: 'DEPLOYING',
	2: 'DEPLOYED',
	3: 'FAILED',
	4: 'CANCELLED',
	5: 'IGNORED',
	6: 'CHECK_UPDATE',
	7: 'DOWNLOAD_START',
	8: 'DOWNLOAD_FAILED',
	9: 'DOWNLOAD_FINISHED',
	10: 'DOWNLOAD_STOP',
	11: 'INSTALL_STARTED',
	12: 'INSTALL_FAILED',
	13: 'INSTALL_FINISHED',
	14: 'DEPLOYMENT_CANCELLED',
	15: 'INSTALL_PENDING',
}

export const osDeploymentStatuses = {
	OPEN: 0,
	DEPLOYING: 1,
	DEPLOYED: 2,
	FAILED: 3,
	CANCELLED: 4,
	IGNORED: 5,
	CHECK_UPDATE: 6,
	DOWNLOAD_START: 7,
	DOWNLOAD_FAILED: 8,
	DOWNLOAD_FINISHED: 9,
	DOWNLOAD_STOP: 10,
	INSTALL_STARTED: 11,
	INSTALL_FAILED: 12,
	INSTALL_FINISHED: 13,
	DEPLOYMENT_CANCELLED: 14,
	INSTALL_PENDING: 15,
}

export const DeviceModes = {
	BED_SIDE: 1,
	ROOM_SIGN: 2,
	TV: 3,
	WHITEBOARD: 4,
	HELLO: 5,
	NURSE_STATION: 6,
}

export const DeviceModeNames = {
	1: 'Bed Side',
	2: 'Roomsign',
	3: 'TV',
	4: 'Whiteboard',
	5: 'Hello',
	6: 'Nurse Station',
}

export const ThemeColors = {
	LIGHT_MODE: {
		BLACK: '#111',
		DARK_BLACK: '#343434',
		WHITE: '#FFF',
		LIGHT_WHITE: '#F6F6F6',
		LIGHT_GRAY: '#FFFFFF0d',
		DARK_GRAY: '#E4E6E8',
	},
}

export const osDeploymentDeviceStatusColors = {
	0: '#E5E5E5',
	1: '#1B4632',
	2: '#198754',
	3: '#494949',
	4: '#F15E5E',
	5: '#963636',
	6: '#0D6EFD',
	7: '#6610F2',
	8: '#D63384',
	9: '#20C997',
	10: '#FD7E14',
	11: '#6F42C1',
	12: '#D9534F',
}
