import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import {
	buttonTheme,
	cardTheme,
	drawerTheme,
	inputTheme,
	modalTheme,
	popoverTheme,
	progressTheme,
	skeletonTheme,
	statTheme,
	switchTheme,
	tableTheme,
	tabsTheme,
} from 'components/chakraComponents'
import { ThemeColors } from 'enums'

const config = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
}

const styles = {
	global: (props) => ({
		body: {
			color: mode('blackAlpha.800', 'whiteAlpha.800')(props),
			backgroundColor: mode(ThemeColors.LIGHT_MODE.LIGHT_WHITE, ThemeColors.LIGHT_MODE.BLACK)(props),
		},
	}),
}

const colors = {
	midGray: '#323130',
	primary: ThemeColors.LIGHT_MODE.WHITE,
	secondary: ThemeColors.LIGHT_MODE.DARK_BLACK,
}

const components = {
	Card: cardTheme,
	Table: tableTheme,
	Skeleton: skeletonTheme,
	Modal: modalTheme,
	Drawer: drawerTheme,
	Stat: statTheme,
	Switch: switchTheme,
	Popover: popoverTheme,
	Input: inputTheme,
	Progress: progressTheme,
	Tabs: tabsTheme,
	Button: buttonTheme,
}

const theme = extendTheme({ styles, components, colors, config })

export default theme
