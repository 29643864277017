import { Icon } from '@chakra-ui/react'
import { ThemeColors } from 'enums'

export const IconThreeDotsVertical = (props) => (
	<Icon viewBox='0 0 16 16' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M9.5 13a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'
		/>
	</Icon>
)

export const ClearIcon = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M899.1 869.6l-53-305.6H864c14.4 0 26-11.6 26-26V346c0-14.4-11.6-26-26-26H618V138c0-14.4-11.6-26-26-26H432c-14.4 0-26 11.6-26 26v182H160c-14.4 0-26 11.6-26 26v192c0 14.4 11.6 26 26 26h17.9l-53 305.6c-.3 1.5-.4 3-.4 4.4 0 14.4 11.6 26 26 26h723c1.5 0 3-.1 4.4-.4 14.2-2.4 23.7-15.9 21.2-30zM204 390h272V182h72v208h272v104H204V390zm468 440V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H416V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H202.8l45.1-260H776l45.1 260H672z' />
	</Icon>
)

export const IconCompanyOutlined = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M15 9h2v2h-2V9m2-4h-2v2h2V5m-6 10h2v-2h-2v2m2-10h-2v2h2V5m-2 6h2V9h-2v2M9 5H7v2h2V5m0 4H7v2h2V9m5.55 12H13v-3.5h-2V21H5V3h14v8.03c.71.06 1.39.28 2 .6V1H3v22h12.91c-.41-.56-.91-1.24-1.36-2M7 19h2v-2H7v2m2-6H7v2h2v-2m13 3.5c0 2.6-3.5 6.5-3.5 6.5S15 19.1 15 16.5c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5m-2.3.1c0-.6-.6-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2s1.3-.6 1.2-1.2z'
		/>
	</Icon>
)

export const IconHospitalOutlined = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='none' d='M0 0h24v24H0z' />
		<path
			fill='currentColor'
			d='M18 14h-4v4h-4v-4H6v-4h4V6h4v4h4m2-8H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 18H4V4h16v16z'
		/>
	</Icon>
)

export const UploadIconOutlined = (props) => (
	<Icon viewBox='0 0 1024 1024' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z'
		/>
	</Icon>
)

export const DownloadIconOutlined = (props) => (
	<Icon viewBox='0 0 1024 1024' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z'
		/>
	</Icon>
)

export const AddIconOutlined = (props) => (
	<Icon viewBox='0 0 20 20' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16 8 8 0 000 16z'
		/>
	</Icon>
)

export const TransferIconOutlined = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='currentColor' d='M15 12l5-4-5-4v2.999H2v2h13zm7 3H9v-3l-5 4 5 4v-3h13z' />
	</Icon>
)

export const FilterIconOutlined = (props) => (
	<Icon viewBox='0 0 1024 1024' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M880.1 154H143.9c-24.5 0-39.8 26.7-27.5 48L349 597.4V838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V597.4L907.7 202c12.2-21.3-3.1-48-27.6-48zM603.4 798H420.6V642h182.9v156zm9.6-236.6l-9.5 16.6h-183l-9.5-16.6L212.7 226h598.6L613 561.4z'
		/>
	</Icon>
)

export const ForwardIcon = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M15.038 6.343l-1.411 1.418 3.27 3.255-13.605.013.002 2 13.568-.013-3.215 3.23 1.417 1.41 5.644-5.67-5.67-5.643z'
		/>
	</Icon>
)

export const BackwardIcon = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='currentColor' d='M20.328 11v2H7.5l3.243 3.243-1.415 1.414L3.672 12l5.656-5.657 1.415 1.414L7.5 11h12.828z' />
	</Icon>
)

export const RepeatIcon = (props) => (
	<Icon
		viewBox='0 0 24 24'
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		boxSize={5}
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M4 12V9a3 3 0 013-3h13m-3-3l3 3-3 3M20 12v3a3 3 0 01-3 3H4m3 3l-3-3 3-3' />
	</Icon>
)

export const DevicesIconOutlined = (props) => (
	<Icon
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		boxSize={5}
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M14 8 H20 A1 1 0 0 1 21 9 V19 A1 1 0 0 1 20 20 H14 A1 1 0 0 1 13 19 V9 A1 1 0 0 1 14 8 z' />
		<path d='M18 8V5a1 1 0 00-1-1H4a1 1 0 00-1 1v12a1 1 0 001 1h9M16 9h2' />
	</Icon>
)

export const AppsIconOutlined = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M16 20h4v-4h-4m0-2h4v-4h-4m-6-2h4V4h-4m6 4h4V4h-4m-6 10h4v-4h-4m-6 4h4v-4H4m0 10h4v-4H4m6 4h4v-4h-4M4 8h4V4H4v4z' />
	</Icon>
)

export const SearchIcon = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z' />
	</Icon>
)

export const CancelIconFilled = (props) => (
	<Icon baseProfile='tiny' viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm-5 8c0-.832.224-1.604.584-2.295l6.711 6.711A4.943 4.943 0 0112 17c-2.757 0-5-2.243-5-5zm9.416 2.295L9.705 7.584A4.943 4.943 0 0112 7c2.757 0 5 2.243 5 5 0 .832-.224 1.604-.584 2.295z' />
	</Icon>
)

export const ViewIconOutline = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M17.56 17.66a8 8 0 01-11.32 0L1.3 12.7a1 1 0 010-1.42l4.95-4.95a8 8 0 0111.32 0l4.95 4.95a1 1 0 010 1.42l-4.95 4.95zm-9.9-1.42a6 6 0 008.48 0L20.38 12l-4.24-4.24a6 6 0 00-8.48 0L3.4 12l4.25 4.24zM11.9 16a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z' />
	</Icon>
)

export const ActivityLogIconOutline = (props) => (
	<Icon fill='none' viewBox='0 0 15 15' boxSize={5} {...props}>
		<path
			fill='currentColor'
			fillRule='evenodd'
			d='M0 1.5A.5.5 0 01.5 1h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm4 0a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-4 3A.5.5 0 01.5 7h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm4 0a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-4 3a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm4 0a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5z'
			clipRule='evenodd'
		/>
	</Icon>
)

export const NotificationCircleIcon = (props) => (
	<Icon viewBox='0 0 16 16' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M8 1.5c-1.736 0-3.369.676-4.596 1.904S1.5 6.264 1.5 8c0 1.736.676 3.369 1.904 4.596S6.264 14.5 8 14.5c1.736 0 3.369-.676 4.596-1.904S14.5 9.736 14.5 8c0-1.736-.676-3.369-1.904-4.596S9.736 1.5 8 1.5zM8 0a8 8 0 110 16A8 8 0 018 0zM7 11h2v2H7zm0-8h2v6H7z'
		/>
	</Icon>
)

export const CloseIconOutline = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={32}
			d='M368 368L144 144M368 144L144 368'
		/>
	</Icon>
)

export const CardListIcon = (props) => (
	<Icon
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		boxSize={5}
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M9 6h11M9 12h11M9 18h11M5 6v.01M5 12v.01M5 18v.01' />
	</Icon>
)

export const PencilIconOutline = (props) => (
	<Icon xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 16 16' boxSize={5} {...props}>
		<path d='M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z' />
		<path
			fillRule='evenodd'
			d='M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z'
		/>
	</Icon>
)

export const DeleteIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z' />
	</Icon>
)

export const CopyIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z' />
	</Icon>
)

export const ArrowDownIconOutline = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={48}
			d='M112 268l144 144 144-144M256 392V100'
		/>
	</Icon>
)

export const DocumentAttachOutline = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={32}
			d='M208 64h66.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62V432a48 48 0 01-48 48H192a48 48 0 01-48-48V304'
		/>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={32}
			d='M288 72v120a32 32 0 0032 32h120'
		/>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeMiterlimit={10}
			strokeWidth={32}
			d='M160 80v152a23.69 23.69 0 01-24 24c-12 0-24-9.1-24-24V88c0-30.59 16.57-56 48-56s48 24.8 48 55.38v138.75c0 43-27.82 77.87-72 77.87s-72-34.86-72-77.87V144'
		/>
	</Icon>
)

export const UserIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z' />
	</Icon>
)

export const ProfileIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656zM492 400h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 144h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 144h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zM340 368a40 40 0 1080 0 40 40 0 10-80 0zm0 144a40 40 0 1080 0 40 40 0 10-80 0zm0 144a40 40 0 1080 0 40 40 0 10-80 0z' />
	</Icon>
)

export const FloorIconOutline = (props) => (
	<Icon fill='none' viewBox='0 0 15 15' boxSize={5} {...props}>
		<path
			fill='currentColor'
			fillRule='evenodd'
			d='M0 0h4.651l3.126 2.084-.554.832L4.349 1H1v13h5V8H4V7h5v1H7v6h7V8h-2V7h2V1h-4V0h5v15H0V0z'
			clipRule='evenodd'
		/>
	</Icon>
)

export const DevicesIconOutline = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M20 3H7c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h6c1.103 0 2-.897 2-2h8c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM9.997 19H4V9h6l-.003 10zm10-2H12V9c0-1.103-.897-2-2-2H7V5h13l-.003 12z' />
	</Icon>
)

export const AndroidIconOutline = (props) => (
	<Icon fill='none' viewBox='0 0 15 15' boxSize={5} {...props}>
		<path stroke='currentColor' d='M2.5 2.5l2 3m8-3l-2 3M4 9.5h1m5 0h1m-9.5 3v-2a6 6 0 1112 0v2h-12z' />
	</Icon>
)

export const AppAddIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<defs>
			<style />
		</defs>
		<path d='M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zm52 132H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200zM424 712H296V584c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v128H104c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h128v128c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V776h128c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z' />
	</Icon>
)

export const AIFileIconOutline = (props) => (
	<Icon fill='currentColor' viewBox='0 0 16 16' boxSize={5} {...props}>
		<path
			fillRule='evenodd'
			d='M14 4.5V14a2 2 0 01-2 2H6v-1h6a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5L14 4.5zM1.113 14.82L.8 15.85H0l1.342-3.999h.926l1.336 3.999h-.841l-.314-1.028H1.113zm1.178-.588l-.49-1.617h-.034l-.49 1.617h1.014zm2.425-2.382v3.999h-.791V11.85h.79z'
		/>
	</Icon>
)

export const FlavorIconOutline = (props) => (
	<Icon fill='currentColor' viewBox='0 0 16 16' boxSize={5} {...props}>
		<path d='M8.235 1.559a.5.5 0 00-.47 0l-7.5 4a.5.5 0 000 .882L3.188 8 .264 9.559a.5.5 0 000 .882l7.5 4a.5.5 0 00.47 0l7.5-4a.5.5 0 000-.882L12.813 8l2.922-1.559a.5.5 0 000-.882l-7.5-4zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 00.47 0l3.515-1.874zM8 9.433L1.562 6 8 2.567 14.438 6 8 9.433z' />
	</Icon>
)

export const ReleaseIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M888.3 693.2c-42.5-24.6-94.3-18-129.2 12.8l-53-30.7V523.6c0-15.7-8.4-30.3-22-38.1l-136-78.3v-67.1c44.2-15 76-56.8 76-106.1 0-61.9-50.1-112-112-112s-112 50.1-112 112c0 49.3 31.8 91.1 76 106.1v67.1l-136 78.3c-13.6 7.8-22 22.4-22 38.1v151.6l-53 30.7c-34.9-30.8-86.8-37.4-129.2-12.8-53.5 31-71.7 99.4-41 152.9 30.8 53.5 98.9 71.9 152.2 41 42.5-24.6 62.7-73 53.6-118.8l48.7-28.3 140.6 81c6.8 3.9 14.4 5.9 22 5.9s15.2-2 22-5.9L674.5 740l48.7 28.3c-9.1 45.7 11.2 94.2 53.6 118.8 53.3 30.9 121.5 12.6 152.2-41 30.8-53.6 12.6-122-40.7-152.9zm-673 138.4a47.6 47.6 0 01-65.2-17.6c-13.2-22.9-5.4-52.3 17.5-65.5a47.6 47.6 0 0165.2 17.6c13.2 22.9 5.4 52.3-17.5 65.5zM522 463.8zM464 234a48.01 48.01 0 0196 0 48.01 48.01 0 01-96 0zm170 446.2l-122 70.3-122-70.3V539.8l122-70.3 122 70.3v140.4zm239.9 133.9c-13.2 22.9-42.4 30.8-65.2 17.6-22.8-13.2-30.7-42.6-17.5-65.5s42.4-30.8 65.2-17.6c22.9 13.2 30.7 42.5 17.5 65.5z' />
	</Icon>
)

export const AppReleaseIconOutline = (props) => (
	<Icon
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		boxSize={5}
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M5 5 H19 A2 2 0 0 1 21 7 V17 A2 2 0 0 1 19 19 H5 A2 2 0 0 1 3 17 V7 A2 2 0 0 1 5 5 z' />
		<path d='M6 8h.01M9 8h.01' />
	</Icon>
)

export const DiagnosticsIcon = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M464 688a48 48 0 1096 0 48 48 0 10-96 0zm72-112c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48zm400-188h-59.3c-2.6 0-5 1.2-6.5 3.3L763.7 538.1l-49.9-68.8a7.92 7.92 0 00-6.5-3.3H648c-6.5 0-10.3 7.4-6.5 12.7l109.2 150.7a16.1 16.1 0 0026 0l165.8-228.7c3.8-5.3 0-12.7-6.5-12.7zm-44 306h-64.2c-5.5 0-10.6 2.9-13.6 7.5a352.2 352.2 0 01-49.8 62.2A355.92 355.92 0 01651.1 840a355 355 0 01-138.7 27.9c-48.1 0-94.8-9.4-138.7-27.9a355.92 355.92 0 01-113.3-76.3A353.06 353.06 0 01184 650.5c-18.6-43.8-28-90.5-28-138.5s9.4-94.7 28-138.5c17.9-42.4 43.6-80.5 76.4-113.2 32.8-32.7 70.9-58.4 113.3-76.3a355 355 0 01138.7-27.9c48.1 0 94.8 9.4 138.7 27.9 42.4 17.9 80.5 43.6 113.3 76.3 19 19 35.6 39.8 49.8 62.2 2.9 4.7 8.1 7.5 13.6 7.5H892c6 0 9.8-6.3 7.2-11.6C828.8 178.5 684.7 82 517.7 80 278.9 77.2 80.5 272.5 80 511.2 79.5 750.1 273.3 944 512.4 944c169.2 0 315.6-97 386.7-238.4A8 8 0 00892 694z' />
	</Icon>
)

export const CreateIconOutline = (props) => (
	<Icon viewBox='0 0 21 21' fill='currentColor' boxSize={5} {...props}>
		<g fill='none' fillRule='evenodd' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'>
			<path d='M10 4.5H5.5a2 2 0 00-2 2v9a2 2 0 002 2h10a2 2 0 002-2V11' />
			<path d='M17.5 3.467a1.462 1.462 0 01-.017 2.05L10.5 12.5l-3 1 1-3 6.987-7.046a1.409 1.409 0 011.885-.104zM15.5 5.5l.953 1' />
		</g>
	</Icon>
)

export const ChevronRightIcon = (props) => (
	<Icon fill='currentColor' viewBox='0 0 16 16' boxSize={5} {...props}>
		<path
			fillRule='evenodd'
			d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z'
		/>
	</Icon>
)

export const SideBarDashboardIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect width={34} height={34} rx={10} fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK} />
		<rect
			x={8}
			y={8}
			width={18}
			height={18}
			rx={4}
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
			fillOpacity={0.5}
		/>
		<path
			d='M10 20.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v2a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm5-3a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm5-4a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5z'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
	</Icon>
)

export const SideBarDashboardLightIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect
			width={34}
			height={34}
			rx={10}
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={isActive ? 1 : 0.1}
		/>
		<rect
			x={8}
			y={8}
			width={18}
			height={18}
			rx={4}
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={0.5}
		/>
		<path
			d='M10 20.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v2a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm5-3a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm5-4a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5z'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
		/>
	</Icon>
)

export const SideBarUserManagementIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect width={34} height={34} rx={10} fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK} />
		<path
			d='M11 22.714V25h10l2-4-.719-1c-1.014-1.787-2.999-3-5.281-3-3.314 0-6 2.558-6 5.714'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
			fillOpacity={0.5}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M22.378 20.078a1 1 0 0 0-.207.4.976.976 0 0 1-1.202.694 1 1 0 0 0-.448-.022c-.33.365-.585.799-.739 1.278a1 1 0 0 0 .243.379.976.976 0 0 1 0 1.388 1 1 0 0 0-.243.377c.155.48.41.915.74 1.28a1 1 0 0 0 .447-.023.976.976 0 0 1 1.202.694 1 1 0 0 0 .205.399 3.5 3.5 0 0 0 1.478 0 1 1 0 0 0 .205-.399.976.976 0 0 1 1.202-.694 1 1 0 0 0 .447.022c.33-.364.586-.799.74-1.279a1 1 0 0 0-.243-.377.976.976 0 0 1 0-1.388 1 1 0 0 0 .243-.379 3.5 3.5 0 0 0-.739-1.278 1 1 0 0 0-.448.022.976.976 0 0 1-1.202-.694 1 1 0 0 0-.206-.4 3.5 3.5 0 0 0-1.476 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
		<path
			d='M21.948 23.5a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
		<path
			d='M13.75 22.5a.75.75 0 0 0-1.5 0zm-1.5 0V25h1.5v-2.5z'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
		<path
			d='M20.25 11.75a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
			fillOpacity={0.5}
		/>
	</Icon>
)

export const SideBarUserManagementLightIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect
			width={34}
			height={34}
			rx={10}
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={isActive ? 1 : 0.1}
		/>
		<path
			d='M11 22.714V25h10l2-4-.719-1c-1.014-1.787-2.999-3-5.281-3-3.314 0-6 2.558-6 5.714'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={0.5}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M22.378 20.078a1 1 0 0 0-.207.4.976.976 0 0 1-1.202.694 1 1 0 0 0-.448-.022c-.33.365-.585.799-.739 1.278a1 1 0 0 0 .243.379.976.976 0 0 1 0 1.388 1 1 0 0 0-.243.377c.155.48.41.915.74 1.28a1 1 0 0 0 .447-.023.976.976 0 0 1 1.202.694 1 1 0 0 0 .205.399 3.5 3.5 0 0 0 1.478 0 1 1 0 0 0 .205-.399.976.976 0 0 1 1.202-.694 1 1 0 0 0 .447.022c.33-.364.586-.799.74-1.279a1 1 0 0 0-.243-.377.976.976 0 0 1 0-1.388 1 1 0 0 0 .243-.379 3.5 3.5 0 0 0-.739-1.278 1 1 0 0 0-.448.022.976.976 0 0 1-1.202-.694 1 1 0 0 0-.206-.4 3.5 3.5 0 0 0-1.476 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
		/>
		<path
			d='M21.948 23.5a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
		/>
		<path
			d='M13.75 22.5a.75.75 0 0 0-1.5 0zm-1.5 0V25h1.5v-2.5z'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
		/>
		<path
			d='M20.25 11.75a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={0.5}
		/>
	</Icon>
)

export const SideBarProvisioningIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect width={34} height={34} rx={10} fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK} />
		<path
			d='M20 16h7a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8.324zm-5.796 0H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8z'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
			fillOpacity={0.5}
		/>
		<path
			d='M21 17a4 4 0 1 1-8 0 4 4 0 0 1 8 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
		<path
			d='M19 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
	</Icon>
)

export const SideBarProvisioningLightIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect
			width={34}
			height={34}
			rx={10}
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={isActive ? 1 : 0.1}
		/>
		<path
			d='M20 16h7a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8.324zm-5.796 0H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8z'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={0.5}
		/>
		<path
			d='M21 17a4 4 0 1 1-8 0 4 4 0 0 1 8 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			d='M19 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.WHITE}
		/>
	</Icon>
)

export const SideBarOTAIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect width={34} height={34} rx={10} fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK} />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M24 13c0 .415.279.792.666.942A3.653 3.653 0 0 1 23.349 21H10.65a3.651 3.651 0 0 1-.89-7.193.32.32 0 0 0 .24-.307 3.5 3.5 0 0 1 4.028-3.46c.473.071.973-.079 1.297-.43A5 5 0 0 1 24 13'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
			fillOpacity={0.5}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M17.319 16.013c-.367.026-.735.187-.884.522a1.5 1.5 0 0 0-.083.243 1.37 1.37 0 0 1-1.706.985 1.5 1.5 0 0 0-.25-.05c-.365-.039-.689.2-.895.503q-.204.301-.365.632c-.16.33-.205.728.01 1.024q.075.102.17.195c.556.539.556 1.43 0 1.97q-.096.092-.168.192c-.217.297-.172.696-.012 1.026q.161.33.366.632c.207.305.531.543.897.502q.12-.013.247-.049a1.37 1.37 0 0 1 1.706.985q.032.127.081.239c.148.337.518.499.886.525a5 5 0 0 0 .727 0c.367-.026.737-.188.885-.525q.05-.112.081-.239a1.37 1.37 0 0 1 1.706-.985q.126.035.247.05c.366.04.69-.198.897-.503q.206-.301.366-.632c.16-.33.205-.729-.012-1.026a1.5 1.5 0 0 0-.168-.192 1.37 1.37 0 0 1 0-1.97q.096-.093.17-.195c.215-.296.17-.694.01-1.024a5 5 0 0 0-.365-.632c-.206-.304-.53-.542-.895-.502a1.5 1.5 0 0 0-.25.05 1.37 1.37 0 0 1-1.706-.986 1.5 1.5 0 0 0-.083-.242c-.149-.336-.517-.497-.883-.523a5 5 0 0 0-.727 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
		<path
			d='M19.683 21a2 2 0 1 1-4 0 2 2 0 0 1 4 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
	</Icon>
)

export const SideBarOTALightIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect
			width={34}
			height={34}
			rx={10}
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={isActive ? 1 : 0.1}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M24 13c0 .415.279.792.666.942A3.653 3.653 0 0 1 23.349 21H10.65a3.651 3.651 0 0 1-.89-7.193.32.32 0 0 0 .24-.307 3.5 3.5 0 0 1 4.028-3.46c.473.071.973-.079 1.297-.43A5 5 0 0 1 24 13'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={0.5}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M17.319 16.013c-.367.026-.735.187-.884.522a1.5 1.5 0 0 0-.083.243 1.37 1.37 0 0 1-1.706.985 1.5 1.5 0 0 0-.25-.05c-.365-.039-.689.2-.895.503q-.204.301-.365.632c-.16.33-.205.728.01 1.024q.075.102.17.195c.556.539.556 1.43 0 1.97q-.096.092-.168.192c-.217.297-.172.696-.012 1.026q.161.33.366.632c.207.305.531.543.897.502q.12-.013.247-.049a1.37 1.37 0 0 1 1.706.985q.032.127.081.239c.148.337.518.499.886.525a5 5 0 0 0 .727 0c.367-.026.737-.188.885-.525q.05-.112.081-.239a1.37 1.37 0 0 1 1.706-.985q.126.035.247.05c.366.04.69-.198.897-.503q.206-.301.366-.632c.16-.33.205-.729-.012-1.026a1.5 1.5 0 0 0-.168-.192 1.37 1.37 0 0 1 0-1.97q.096-.093.17-.195c.215-.296.17-.694.01-1.024a5 5 0 0 0-.365-.632c-.206-.304-.53-.542-.895-.502a1.5 1.5 0 0 0-.25.05 1.37 1.37 0 0 1-1.706-.986 1.5 1.5 0 0 0-.083-.242c-.149-.336-.517-.497-.883-.523a5 5 0 0 0-.727 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			d='M19.683 21a2 2 0 1 1-4 0 2 2 0 0 1 4 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.WHITE}
		/>
	</Icon>
)

export const SideBarDeviceManagementIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect width={34} height={34} rx={10} fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK} />
		<path
			d='M18.314 16H27a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8.892a1 1 0 0 1-.995-1.102l.206-2a1 1 0 0 1 .995-.898m-2.628 0H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8.892a1 1 0 0 0 .995-1.102l-.206-2a1 1 0 0 0-.995-.898'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
			fillOpacity={0.5}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M16.636 12.013c-.366.026-.734.187-.883.523a1.5 1.5 0 0 0-.084.242 1.37 1.37 0 0 1-1.705.985 1.5 1.5 0 0 0-.25-.05c-.366-.039-.69.2-.895.503q-.206.301-.366.632c-.16.33-.205.728.011 1.024q.073.102.17.195c.556.539.556 1.43 0 1.97q-.096.092-.169.192c-.216.297-.172.696-.011 1.026q.16.33.366.632c.206.305.53.543.896.502q.122-.013.248-.049a1.37 1.37 0 0 1 1.705.985q.033.127.082.239c.148.337.517.499.885.525a5 5 0 0 0 .727 0c.368-.026.737-.188.885-.525q.05-.112.082-.239a1.37 1.37 0 0 1 1.706-.985q.125.035.247.05c.365.04.69-.198.896-.503q.206-.301.366-.632c.16-.33.205-.729-.011-1.026a1.5 1.5 0 0 0-.168-.192 1.37 1.37 0 0 1 0-1.97q.096-.093.169-.194c.216-.297.171-.695.011-1.025a5 5 0 0 0-.366-.632c-.206-.304-.53-.542-.895-.502a1.5 1.5 0 0 0-.25.05 1.37 1.37 0 0 1-1.705-.986 1.5 1.5 0 0 0-.083-.242c-.15-.336-.518-.497-.884-.523a5 5 0 0 0-.727 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
		<path
			d='M19 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
	</Icon>
)

export const SideBarDeviceManagementLightIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect
			width={34}
			height={34}
			rx={10}
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={isActive ? 1 : 0.1}
		/>
		<path
			d='M18.314 16H27a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8.892a1 1 0 0 1-.995-1.102l.206-2a1 1 0 0 1 .995-.898m-2.628 0H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8.892a1 1 0 0 0 .995-1.102l-.206-2a1 1 0 0 0-.995-.898'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={0.5}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M16.636 12.013c-.366.026-.734.187-.883.523a1.5 1.5 0 0 0-.084.242 1.37 1.37 0 0 1-1.705.985 1.5 1.5 0 0 0-.25-.05c-.366-.039-.69.2-.895.503q-.206.301-.366.632c-.16.33-.205.728.011 1.024q.073.102.17.195c.556.539.556 1.43 0 1.97q-.096.092-.169.192c-.216.297-.172.696-.011 1.026q.16.33.366.632c.206.305.53.543.896.502q.122-.013.248-.049a1.37 1.37 0 0 1 1.705.985q.033.127.082.239c.148.337.517.499.885.525a5 5 0 0 0 .727 0c.368-.026.737-.188.885-.525q.05-.112.082-.239a1.37 1.37 0 0 1 1.706-.985q.125.035.247.05c.365.04.69-.198.896-.503q.206-.301.366-.632c.16-.33.205-.729-.011-1.026a1.5 1.5 0 0 0-.168-.192 1.37 1.37 0 0 1 0-1.97q.096-.093.169-.194c.216-.297.171-.695.011-1.025a5 5 0 0 0-.366-.632c-.206-.304-.53-.542-.895-.502a1.5 1.5 0 0 0-.25.05 1.37 1.37 0 0 1-1.705-.986 1.5 1.5 0 0 0-.083-.242c-.15-.336-.518-.497-.884-.523a5 5 0 0 0-.727 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
		/>
		<path
			d='M19 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.WHITE}
		/>
	</Icon>
)

export const SideBarMLIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect width={34} height={34} rx={10} fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK} />
		<path
			d='M15 10V9m4 1V9m4 1V9m-8 16v-1m4 1v-1m4 1v-1m3-11h1m-1 4h1m-1 4h1m-15-8h-1l-1-1m-2 5h4m0 4h-1l-1 1m-2-5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
			strokeOpacity={0.5}
			strokeWidth={1.5}
			strokeLinecap='round'
		/>
		<rect
			x={12}
			y={10}
			width={14}
			height={14}
			rx={2}
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M18.773 12.747c-.367.02-.741.183-.868.528a1 1 0 0 0-.04.13 1.17 1.17 0 0 1-1.455.84 1 1 0 0 0-.132-.03c-.361-.063-.69.178-.89.486a4 4 0 0 0-.23.397c-.167.327-.212.731.023 1.013q.043.051.094.1a1.17 1.17 0 0 1 0 1.681 1 1 0 0 0-.093.1c-.236.281-.19.687-.024 1.014q.105.205.23.397c.2.308.53.55.893.485q.063-.012.129-.03a1.17 1.17 0 0 1 1.455.84q.017.067.039.127c.125.347.5.511.869.53a4 4 0 0 0 .455 0c.368-.019.743-.183.869-.53q.021-.06.038-.127a1.17 1.17 0 0 1 1.456-.84q.066.02.13.03c.362.064.691-.177.892-.485q.125-.192.23-.397c.166-.327.212-.732-.024-1.014a1 1 0 0 0-.093-.1 1.17 1.17 0 0 1 0-1.68q.05-.05.094-.102c.235-.281.19-.686.023-1.012a4 4 0 0 0-.23-.397c-.2-.308-.528-.55-.89-.486a1 1 0 0 0-.132.03 1.17 1.17 0 0 1-1.456-.84 1 1 0 0 0-.04-.13c-.126-.345-.5-.509-.867-.528a4 4 0 0 0-.456 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			d='M20.5 17a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.LIGHT_WHITE}
		/>
	</Icon>
)

export const SideBarMLLightIcon = ({ isActive, ...rest }) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='8' {...rest}>
		<rect
			width={34}
			height={34}
			rx={10}
			fill={isActive ? ThemeColors.LIGHT_MODE.DARK_BLACK : ThemeColors.LIGHT_MODE.BLACK}
			fillOpacity={isActive ? 1 : 0.1}
		/>
		<path
			d='M15 10V9m4 1V9m4 1V9m-8 16v-1m4 1v-1m4 1v-1m3-11h1m-1 4h1m-1 4h1m-15-8h-1l-1-1m-2 5h4m0 4h-1l-1 1m-2-5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z'
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
			strokeOpacity={0.5}
			strokeWidth={1.5}
			strokeLinecap='round'
		/>
		<rect
			x={12}
			y={10}
			width={14}
			height={14}
			rx={2}
			fill={isActive ? ThemeColors.LIGHT_MODE.LIGHT_WHITE : ThemeColors.LIGHT_MODE.BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M18.773 12.747c-.367.02-.741.183-.868.528a1 1 0 0 0-.04.13 1.17 1.17 0 0 1-1.455.84 1 1 0 0 0-.132-.03c-.361-.063-.69.178-.89.486a4 4 0 0 0-.23.397c-.167.327-.212.731.023 1.013q.043.051.094.1a1.17 1.17 0 0 1 0 1.681 1 1 0 0 0-.093.1c-.236.281-.19.687-.024 1.014q.105.205.23.397c.2.308.53.55.893.485q.063-.012.129-.03a1.17 1.17 0 0 1 1.455.84q.017.067.039.127c.125.347.5.511.869.53a4 4 0 0 0 .455 0c.368-.019.743-.183.869-.53q.021-.06.038-.127a1.17 1.17 0 0 1 1.456-.84q.066.02.13.03c.362.064.691-.177.892-.485q.125-.192.23-.397c.166-.327.212-.732-.024-1.014a1 1 0 0 0-.093-.1 1.17 1.17 0 0 1 0-1.68q.05-.05.094-.102c.235-.281.19-.686.023-1.012a4 4 0 0 0-.23-.397c-.2-.308-.528-.55-.89-.486a1 1 0 0 0-.132.03 1.17 1.17 0 0 1-1.456-.84 1 1 0 0 0-.04-.13c-.126-.345-.5-.509-.867-.528a4 4 0 0 0-.456 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.BLACK : ThemeColors.LIGHT_MODE.WHITE}
		/>
		<path
			d='M20.5 17a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0'
			fill={isActive ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.BLACK}
		/>
	</Icon>
)

export const SolaborateLongIcon = ({ isDarkMode, ...rest }) => (
	<Icon width={160} height={25} viewBox='0 0 160 25' xmlns='http://www.w3.org/2000/svg' {...rest}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M5.60379 17.3587H10.5669C11.0611 17.3587 11.535 17.555 11.8844 17.9045C12.2338 18.2539 12.4301 18.7278 12.4301 19.2219C12.4301 19.7161 12.2338 20.19 11.8844 20.5394C11.535 20.8888 11.0611 21.0851 10.5669 21.0851H0.0243289C0.408936 22.1756 1.12263 23.1198 2.06686 23.7873C3.0111 24.4548 4.13928 24.8127 5.29562 24.8115H10.5669C12.0212 24.7723 13.4027 24.1671 14.4174 23.1246C15.4322 22.0822 16 20.6848 16 19.23C16 17.7753 15.4322 16.3779 14.4174 15.3355C13.4027 14.293 12.0212 13.6878 10.5669 13.6486H5.60379C5.11018 13.6486 4.63678 13.4525 4.28774 13.1034C3.9387 12.7544 3.74262 12.281 3.74262 11.7874C3.74262 11.2938 3.9387 10.8204 4.28774 10.4713C4.63678 10.1223 5.11018 9.92622 5.60379 9.92622H14.9056C14.5201 8.8369 13.806 7.89412 12.8618 7.22807C11.9175 6.56202 10.7898 6.20557 9.6343 6.20793H5.57946C4.0997 6.20793 2.68054 6.79577 1.63419 7.84212C0.587834 8.88847 0 10.3076 0 11.7874C0 13.2672 0.587834 14.6863 1.63419 15.7327C2.68054 16.779 4.0997 17.3669 5.57946 17.3669L5.60379 17.3587Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M52.1128 6.19984C52.8453 6.19984 53.5706 6.34418 54.2473 6.62463C54.924 6.90507 55.5388 7.31611 56.0566 7.83427C56.5744 8.35242 56.985 8.96753 57.2649 9.64444C57.5449 10.3213 57.6887 11.0468 57.6882 11.7793V19.2199C57.6887 19.9526 57.5449 20.6783 57.265 21.3554C56.9851 22.0325 56.5746 22.6479 56.0569 23.1664C55.5391 23.6849 54.9244 24.0963 54.2476 24.3772C53.5709 24.6581 52.8455 24.8029 52.1128 24.8034H47.1456C45.6913 24.7642 44.3098 24.159 43.2951 23.1165C42.2803 22.0741 41.7125 20.6767 41.7125 19.222C41.7125 17.7672 42.2803 16.3698 43.2951 15.3274C44.3098 14.2849 45.6913 13.6797 47.1456 13.6405H53.9537V11.7793C53.9537 11.2864 53.7581 10.8136 53.41 10.4647C53.0618 10.1158 52.5895 9.9192 52.0965 9.91812H42.7704C43.1564 8.82913 43.8706 7.88669 44.8148 7.22072C45.7589 6.55476 46.8863 6.19807 48.0417 6.19984H52.1128ZM52.1128 21.077H47.1456C46.6675 21.0547 46.2164 20.8491 45.886 20.5029C45.5556 20.1567 45.3713 19.6965 45.3713 19.2179C45.3713 18.7393 45.5556 18.2791 45.886 17.9329C46.2164 17.5867 46.6675 17.3811 47.1456 17.3588H53.9537V19.2199C53.9558 19.7111 53.7633 20.1831 53.4183 20.5326C53.0733 20.8822 52.6039 21.0809 52.1128 21.0852V21.077Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M119.699 21.0852C119.943 21.0857 120.185 21.038 120.411 20.945C120.637 20.8519 120.842 20.7152 121.015 20.5427C121.188 20.3702 121.325 20.1652 121.418 19.9397C121.512 19.7141 121.56 19.4723 121.56 19.228L121.532 17.3669H114.74C114.488 17.3551 114.237 17.3945 114.001 17.4826C113.766 17.5708 113.55 17.7058 113.368 17.8796C113.186 18.0534 113.041 18.2623 112.942 18.4938C112.843 18.7252 112.792 18.9743 112.792 19.226C112.792 19.4777 112.843 19.7268 112.942 19.9583C113.041 20.1897 113.186 20.3986 113.368 20.5724C113.55 20.7462 113.766 20.8813 114.001 20.9694C114.237 21.0575 114.488 21.0969 114.74 21.0852H119.699ZM119.699 6.20795C121.177 6.20794 122.595 6.79467 123.641 7.83926C124.687 8.88386 125.276 10.3009 125.278 11.7793V19.2199C125.278 20.7001 124.691 22.1196 123.644 23.1666C122.598 24.2136 121.179 24.8024 119.699 24.8034H114.74C113.994 24.8235 113.252 24.694 112.557 24.4226C111.863 24.1511 111.229 23.7432 110.695 23.2229C110.16 22.7027 109.735 22.0806 109.445 21.3934C109.155 20.7062 109.006 19.9679 109.006 19.222C109.006 18.4761 109.155 17.7377 109.445 17.0505C109.735 16.3633 110.16 15.7413 110.695 15.221C111.229 14.7007 111.863 14.2928 112.557 14.0213C113.252 13.7499 113.994 13.6204 114.74 13.6405H121.556V11.7793C121.555 11.286 121.358 10.8132 121.01 10.4644C120.661 10.1156 120.188 9.9192 119.695 9.91812H110.397C110.783 8.82913 111.497 7.88669 112.441 7.22072C113.386 6.55476 114.513 6.19807 115.668 6.19984L119.699 6.20795Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M149.461 9.91812C148.972 9.92451 148.504 10.1233 148.16 10.4715C147.815 10.8197 147.622 11.2895 147.621 11.7793L147.649 13.6405H154.441C154.934 13.6405 155.408 13.4444 155.757 13.0953C156.106 12.7463 156.302 12.2729 156.302 11.7793C156.302 11.2857 156.106 10.8123 155.757 10.4632C155.408 10.1142 154.934 9.91812 154.441 9.91812H149.461ZM149.461 24.8034C147.981 24.8024 146.562 24.2136 145.516 23.1666C144.47 22.1196 143.882 20.7001 143.882 19.2199V11.7793C143.882 10.2995 144.47 8.88037 145.516 7.83402C146.563 6.78766 147.982 6.19983 149.461 6.19983H154.421C155.153 6.19983 155.879 6.34415 156.556 6.62454C157.233 6.90493 157.848 7.31592 158.366 7.83402C158.884 8.35212 159.295 8.96719 159.575 9.64412C159.856 10.3211 160 11.0466 160 11.7793C160 12.512 159.856 13.2375 159.575 13.9145C159.295 14.5914 158.884 15.2065 158.366 15.7246C157.848 16.2427 157.233 16.6536 156.556 16.934C155.879 17.2144 155.153 17.3587 154.421 17.3587H147.621V19.224C147.619 19.7144 147.812 20.1854 148.157 20.5341C148.502 20.8828 148.971 21.0809 149.461 21.0851H158.763C158.378 22.1753 157.664 23.1192 156.72 23.7866C155.776 24.454 154.648 24.8121 153.492 24.8115L149.461 24.8034Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M34.1296 11.7793V19.2199C34.1296 20.7008 33.5413 22.121 32.4942 23.1681C31.4471 24.2152 30.0269 24.8034 28.5461 24.8034H23.587C22.1068 24.8024 20.6877 24.2136 19.6415 23.1666C18.5952 22.1196 18.0075 20.7001 18.0075 19.2199V11.7793C18.0075 10.2995 18.5954 8.88037 19.6417 7.83402C20.6881 6.78767 22.1072 6.19983 23.587 6.19983H28.5461C29.2791 6.1993 30.0051 6.34322 30.6825 6.62338C31.3599 6.90353 31.9754 7.31443 32.4939 7.83258C33.0125 8.35074 33.4238 8.96599 33.7045 9.64319C33.9851 10.3204 34.1296 11.0462 34.1296 11.7793ZM23.587 21.0851H28.5461C28.7903 21.0857 29.0322 21.038 29.258 20.9449C29.4838 20.8519 29.689 20.7151 29.8619 20.5426C30.0347 20.3701 30.1719 20.1652 30.2655 19.9397C30.3591 19.7141 30.4072 19.4723 30.4072 19.228V11.7793C30.4072 11.5349 30.3591 11.2929 30.2656 11.0671C30.172 10.8412 30.0349 10.6361 29.8621 10.4632C29.6893 10.2904 29.4841 10.1533 29.2583 10.0598C29.0325 9.96626 28.7905 9.91812 28.5461 9.91812H23.587C23.3428 9.91812 23.1009 9.96629 22.8754 10.0599C22.6498 10.1535 22.4449 10.2906 22.2724 10.4635C22.0999 10.6364 21.9632 10.8416 21.8701 11.0674C21.777 11.2931 21.7293 11.5351 21.7299 11.7793V19.2199C21.7283 19.4646 21.7751 19.7072 21.8678 19.9337C21.9605 20.1602 22.0971 20.3661 22.2697 20.5395C22.4424 20.713 22.6477 20.8505 22.8738 20.9441C23.0999 21.0378 23.3423 21.0857 23.587 21.0851Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M140.464 9.91814C140.078 8.82915 139.363 7.8867 138.419 7.22074C137.475 6.55477 136.348 6.19808 135.192 6.19985H130.858V0C129.769 0.385446 128.826 1.09959 128.16 2.04383C127.494 2.98806 127.137 4.11579 127.14 5.27129V19.2199C127.14 20.7001 127.727 22.1197 128.773 23.1667C129.82 24.2136 131.239 24.8024 132.719 24.8035H136.774C137.93 24.804 139.058 24.4459 140.002 23.7785C140.946 23.1111 141.66 22.1672 142.045 21.0771H132.719C132.475 21.0776 132.233 21.0299 132.007 20.9369C131.781 20.8438 131.576 20.7071 131.403 20.5346C131.23 20.3621 131.093 20.1571 131 19.9316C130.906 19.706 130.858 19.4642 130.858 19.2199V9.91814H140.464Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M93.6547 11.7793V19.2199C93.6547 20.7001 93.067 22.1196 92.0208 23.1666C90.9745 24.2136 89.5554 24.8024 88.0752 24.8034H83.1121C81.632 24.8024 80.2128 24.2136 79.1666 23.1666C78.1203 22.1196 77.5326 20.7001 77.5326 19.2199V11.7793C77.5337 10.2999 78.1219 8.88132 79.168 7.8352C80.2141 6.78908 81.6327 6.2009 83.1121 6.19983H88.0752C89.555 6.19983 90.9742 6.78766 92.0205 7.83402C93.0669 8.88037 93.6547 10.2995 93.6547 11.7793ZM83.1121 21.0851H88.0752C88.3195 21.0857 88.5614 21.038 88.7872 20.9449C89.0129 20.8519 89.2182 20.7151 89.391 20.5426C89.5639 20.3701 89.7011 20.1652 89.7947 19.9397C89.8882 19.7141 89.9364 19.4722 89.9364 19.228V11.7793C89.9353 11.286 89.7389 10.8132 89.3901 10.4644C89.0413 10.1156 88.5685 9.91919 88.0752 9.91812H83.1121C82.6192 9.91919 82.1468 10.1158 81.7987 10.4647C81.4505 10.8136 81.255 11.2864 81.255 11.7793V19.2199C81.2534 19.4646 81.3003 19.7072 81.3929 19.9337C81.4856 20.1602 81.6222 20.3661 81.7948 20.5395C81.9675 20.713 82.1728 20.8505 82.3989 20.9441C82.625 21.0378 82.8674 21.0857 83.1121 21.0851Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M65.1288 21.0852C64.8848 21.0846 64.6433 21.0359 64.4181 20.9418C64.193 20.8477 63.9886 20.71 63.8168 20.5367C63.645 20.3634 63.5091 20.1578 63.417 19.9319C63.3248 19.7059 63.2782 19.464 63.2798 19.2199V9.91814H70.092C70.5852 9.91921 71.058 10.1156 71.4068 10.4644C71.7556 10.8133 71.9521 11.286 71.9531 11.7793V19.2199C71.9542 19.4648 71.9068 19.7075 71.8137 19.9341C71.7206 20.1606 71.5836 20.3664 71.4106 20.5398C71.2377 20.7132 71.0321 20.8506 70.8058 20.9442C70.5795 21.0378 70.3369 21.0857 70.092 21.0852H65.1288ZM63.2798 6.19985V0C62.1897 0.384769 61.2461 1.09863 60.5793 2.04292C59.9125 2.98721 59.5554 4.1153 59.5575 5.27129V19.2199C59.5575 20.7001 60.1452 22.1197 61.1914 23.1667C62.2377 24.2136 63.6568 24.8024 65.1369 24.8035H70.092C70.825 24.8035 71.5509 24.659 72.2281 24.3784C72.9053 24.0977 73.5205 23.6864 74.0387 23.1678C74.5568 22.6493 74.9677 22.0338 75.2479 21.3564C75.528 20.679 75.672 19.953 75.6714 19.2199V11.7793C75.672 11.0465 75.528 10.3207 75.2478 9.64351C74.9676 8.96634 74.5566 8.35105 74.0384 7.83285C73.5202 7.31464 72.9049 6.90368 72.2278 6.62348C71.5506 6.34327 70.8248 6.19932 70.092 6.19985H63.2798Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M101.095 6.19984H103.893C105.049 6.19748 106.176 6.55393 107.121 7.21998C108.065 7.88603 108.779 8.82881 109.164 9.91813H101.095C100.602 9.9192 100.129 10.1156 99.7804 10.4644C99.4316 10.8132 99.2352 11.286 99.2341 11.7793V24.8034H95.5158V11.7793C95.5158 10.2995 96.1037 8.88038 97.15 7.83403C98.1964 6.78768 99.6155 6.19984 101.095 6.19984Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M39.7091 5.27129V24.8156H35.9908V0C37.0801 0.385446 38.0229 1.09959 38.689 2.04383C39.355 2.98806 39.7115 4.11579 39.7091 5.27129Z'
			fill={isDarkMode ? ThemeColors.LIGHT_MODE.WHITE : ThemeColors.LIGHT_MODE.DARK_BLACK}
		/>
	</Icon>
)
export const MoonDarkIcon = (props) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<rect width={34} height={34} rx={10} fill={ThemeColors.LIGHT_MODE.DARK_BLACK} />
		<path
			d='M9 16.83a8.17 8.17 0 0 0 16 2.335s-3.746 1.75-7.83-2.334C13.084 12.746 14.834 9 14.834 9A8.17 8.17 0 0 0 9 16.83'
			stroke={ThemeColors.LIGHT_MODE.LIGHT_WHITE}
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</Icon>
)

export const MoonLightIcon = (props) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<rect width={34} height={34} rx={10} fill={ThemeColors.LIGHT_MODE.WHITE} />
		<path
			d='M9 16.83a8.17 8.17 0 0 0 16 2.335s-3.746 1.75-7.83-2.334C13.084 12.746 14.834 9 14.834 9A8.17 8.17 0 0 0 9 16.83'
			stroke={ThemeColors.LIGHT_MODE.BLACK}
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</Icon>
)

export const SignOutLightIcon = (props) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<rect width={34} height={34} rx={10} fill={ThemeColors.LIGHT_MODE.DARK_BLACK} fillOpacity={0.1} />
		<path
			d='M21 12.857V12a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-.857'
			stroke={ThemeColors.LIGHT_MODE.DARK_BLACK}
			strokeOpacity={0.2}
			strokeWidth={1.5}
			strokeLinecap='round'
		/>
		<path
			d='m24 14 3 3-3 3'
			stroke={ThemeColors.LIGHT_MODE.DARK_BLACK}
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M17 17h10' stroke={ThemeColors.LIGHT_MODE.DARK_BLACK} strokeWidth={1.5} strokeLinecap='round' />
	</Icon>
)

export const SignOutDarkIcon = (props) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<rect width={34} height={34} rx={10} fill={ThemeColors.LIGHT_MODE.WHITE} fillOpacity={0.1} />
		<path
			d='M21 12.857V12a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-.857'
			stroke={ThemeColors.LIGHT_MODE.WHITE}
			strokeOpacity={0.2}
			strokeWidth={1.5}
			strokeLinecap='round'
		/>
		<path
			d='m24 14 3 3-3 3'
			stroke={ThemeColors.LIGHT_MODE.WHITE}
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M17 17h10' stroke={ThemeColors.LIGHT_MODE.WHITE} strokeWidth={1.5} strokeLinecap='round' />
	</Icon>
)

export const UserLightIcon = (props) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<rect width={34} height={34} rx={10} fill={ThemeColors.LIGHT_MODE.DARK_BLACK} fillOpacity={0.1} />
		<circle cx={17} cy={17} r={11} fill={ThemeColors.LIGHT_MODE.DARK_BLACK} fillOpacity={0.2} />
		<mask
			id='a'
			style={{
				maskType: 'alpha',
			}}
			maskUnits='userSpaceOnUse'
			x={6}
			y={6}
			width={22}
			height={22}>
			<circle cx={17} cy={17} r={11} fill='#C4C4C4' />
		</mask>
		<g mask='url(#a)' strokeLinecap='round'>
			<path
				d='M22.5 46.5V23.696a5.5 5.5 0 1 0-11 0v22.806z'
				fill={ThemeColors.LIGHT_MODE.DARK_BLACK}
				stroke={ThemeColors.LIGHT_MODE.DARK_BLACK}
			/>
			<path d='M20.5 24v3.5m-7-3.5v3.5' stroke={ThemeColors.LIGHT_MODE.LIGHT_WHITE} strokeWidth={1.5} strokeLinejoin='round' />
			<path
				d='M20 13a3 3 0 1 1-6 0 3 3 0 0 1 6 0'
				fill={ThemeColors.LIGHT_MODE.DARK_BLACK}
				stroke={ThemeColors.LIGHT_MODE.DARK_BLACK}
				strokeLinejoin='round'
			/>
		</g>
	</Icon>
)

export const UserDarkIcon = (props) => (
	<Icon viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<rect width={34} height={34} rx={10} fill={ThemeColors.LIGHT_MODE.WHITE} fillOpacity={0.1} />
		<circle cx={17} cy={17} r={11} fill={ThemeColors.LIGHT_MODE.WHITE} fillOpacity={0.2} />
		<mask
			id='a'
			style={{
				maskType: 'alpha',
			}}
			maskUnits='userSpaceOnUse'
			x={6}
			y={6}
			width={22}
			height={22}>
			<circle cx={17} cy={17} r={11} fill='#C4C4C4' />
		</mask>
		<g mask='url(#a)' strokeLinecap='round'>
			<path
				d='M22.5 46.5V23.696a5.5 5.5 0 1 0-11 0v22.806z'
				fill={ThemeColors.LIGHT_MODE.WHITE}
				stroke={ThemeColors.LIGHT_MODE.WHITE}
			/>
			<path d='M20.5 24v3.5m-7-3.5v3.5' stroke={ThemeColors.LIGHT_MODE.BLACK} strokeWidth={1.5} strokeLinejoin='round' />
			<path
				d='M20 13a3 3 0 1 1-6 0 3 3 0 0 1 6 0'
				fill={ThemeColors.LIGHT_MODE.WHITE}
				stroke={ThemeColors.LIGHT_MODE.WHITE}
				strokeLinejoin='round'
			/>
		</g>
	</Icon>
)

export const RemoveIcon = (props) => (
	<Icon viewBox='0 0 64 64' fill='currentColor' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<path
			fill='none'
			stroke='currentColor'
			strokeMiterlimit={10}
			strokeWidth={2}
			d='M18.947 17.153l26.098 25.903M19.045 43.153l25.902-26.097'
		/>
	</Icon>
)

export const ProgressIcon = (props) => (
	<Icon viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<path
			d='M10 1.667V5m0 10v3.333M4.108 4.108l2.359 2.359m7.066 7.066 2.359 2.359M1.667 10H5m10 0h3.333M4.108 15.892l2.359-2.359m7.066-7.066 2.359-2.359'
			stroke='currentcolor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</Icon>
)

export const CheckedIcon = (props) => (
	<Icon viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<path
			d='M17.5 10a7.5 7.5 0 1 0-15 0 7.5 7.5 0 0 0 15 0'
			stroke='#33C27F'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M13.638 8.334 9.167 12.5l-2.195-2.045'
			stroke='#33C27F'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</Icon>
)

export const RejectedIcon = (props) => (
	<Icon viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<path
			d='M17.5 10a7.5 7.5 0 1 0-15 0 7.5 7.5 0 0 0 15 0M7.643 7.643l4.714 4.714m-4.714 0 4.714-4.714'
			stroke='#F15E5E'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</Icon>
)

export const PendingIcon = (props) => (
	<Icon viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' fill='currentcolor' boxSize='5' {...props}>
		<path d='M42 0a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h3v5.295C23.364 15.785 6.5 34.209 6.5 56.5 6.5 80.483 26.017 100 50 100s43.5-19.517 43.5-43.5a43.2 43.2 0 0 0-6.72-23.182l4.238-3.431 1.888 2.332a2 2 0 0 0 2.813.297l3.11-2.518a2 2 0 0 0 .294-2.812L89.055 14.75a2 2 0 0 0-2.813-.297l-3.11 2.518a2 2 0 0 0-.294 2.812l1.889 2.332-4.22 3.414C73.77 18.891 64.883 14.435 55 13.297V8h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm8 20c20.2 0 36.5 16.3 36.5 36.5S70.2 93 50 93 13.5 76.7 13.5 56.5 29.8 20 50 20m.002 7.443L50 56.5l23.234 17.447a29.06 29.06 0 0 0 2.758-30.433 29.06 29.06 0 0 0-25.99-16.07z' />
	</Icon>
)

export const FailedIcon = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<path d='M11.001 10h2v5h-2zM11 16h2v2h-2z' />
		<path d='M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 00.054 1.968A1.984 1.984 0 004.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 00.054-1.968L13.768 4.2zM4.661 19L12 5.137 19.344 19H4.661z' />
	</Icon>
)

export const HalfIcon = (props) => (
	<Icon
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		xmlns='http://www.w3.org/2000/svg'
		boxSize='5'
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M12 4v16' />
		<path d='M6 4 H18 A2 2 0 0 1 20 6 V18 A2 2 0 0 1 18 20 H6 A2 2 0 0 1 4 18 V6 A2 2 0 0 1 6 4 z' />
		<path d='M12 13l7.5-7.5M12 18l8-8M15 20l5-5M12 8l4-4' />
	</Icon>
)

export const ChevronRightShortIcon = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<path d='M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42z' />
	</Icon>
)

export const ChevronLeftShortIcon = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<path d='M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.42z' />
	</Icon>
)

export const TreeViewIcon = (props) => (
	<Icon viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<rect x={4} y={4} width={8} height={4} rx={1} stroke='currentColor' strokeWidth={1.5} />
		<rect x={12} y={10} width={8} height={4} rx={1} stroke='currentColor' strokeWidth={1.5} />
		<rect x={12} y={16} width={8} height={4} rx={1} stroke='currentColor' strokeWidth={1.5} />
		<path d='M8 8v4m4 6H9a1 1 0 0 1-1-1v-5m0 0h4' stroke='currentColor' strokeWidth={1.5} />
	</Icon>
)
export const SettingsIcon = (props) => (
	<Icon viewBox='0 0 24 25' stroke='currentColor' xmlns='http://www.w3.org/2000/svg' boxSize='5' {...props}>
		<path
			d='M12 15.412v-5.5m1.89-6.12-.195-.5a1.38 1.38 0 0 0-1.287-.88h-.816c-.569 0-1.08.35-1.287.88l-.195.5a2.327 2.327 0 0 1-3.435 1.106 1.164 1.164 0 0 0-1.456.153l-.58.58a1.164 1.164 0 0 0-.153 1.456 2.327 2.327 0 0 1-1.107 3.435l-.5.195c-.53.207-.879.718-.879 1.288v.815c0 .57.35 1.08.88 1.287l.5.195a2.327 2.327 0 0 1 1.106 3.435 1.164 1.164 0 0 0 .153 1.456l.58.58c.388.388.995.452 1.456.154a2.327 2.327 0 0 1 3.435 1.106l.195.5c.207.53.718.88 1.287.88h.816c.569 0 1.08-.35 1.287-.88l.195-.5a2.327 2.327 0 0 1 3.435-1.107c.46.3 1.068.235 1.456-.153l.58-.58c.388-.388.452-.995.153-1.456a2.327 2.327 0 0 1 1.107-3.435l.5-.195c.53-.207.879-.718.879-1.287v-.815c0-.57-.35-1.081-.88-1.288l-.5-.195a2.327 2.327 0 0 1-1.106-3.435 1.164 1.164 0 0 0-.153-1.456l-.58-.58a1.164 1.164 0 0 0-1.456-.153 2.327 2.327 0 0 1-3.435-1.106Z'
			stroke='#fff'
			strokeWidth={1.5}
			strokeLinecap='round'
		/>
	</Icon>
)
