import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { ThemeColors } from 'components/chakraComponents/ThemeColors'

const primary = defineStyle({
	background: ThemeColors.BACKGROUND_PRIMARY_DARK,
	color: ThemeColors.WHITE,

	_dark: {
		background: ThemeColors.WHITE,
		color: ThemeColors.BLACK_ALPHA900,
	},
})

export const buttonTheme = defineStyleConfig({
	variants: { primary },
})
