const dev = process.env.NODE_ENV === 'development'
const test = process.env.NODE_ENV === 'test'

const authScope = 'openid profile email offline_access roles sol.user sol.hac.user API api.gateway'

export const BASE_URL = dev || test ? process.env.REACT_APP_GATEWAY_API_BASE_URL : window.__env__.REACT_APP_GATEWAY_API_BASE_URL
export const MOB_API_URL = dev || test ? process.env.REACT_APP_MOB_API_URL : window.__env__.REACT_APP_MOB_API_URL

export const authConfig = {
	authority: dev || test ? process.env.REACT_APP_IDSRV_URL : window.__env__.REACT_APP_IDSRV_URL,
	clientId: dev || test ? process.env.REACT_APP_IDSRV_CLIENT_ID : window.__env__.REACT_APP_IDSRV_CLIENT_ID,
	redirectUri: dev || test ? process.env.REACT_APP_IDSRV_REDIRECT_URL : window.__env__.REACT_APP_IDSRV_REDIRECT_URL,
	responseMode: dev || test ? process.env.REACT_APP_IDSRV_RESPONSE_MODE : window.__env__.REACT_APP_IDSRV_RESPONSE_MODE,
	scope: authScope,
	login: dev || test ? process.env.REACT_APP_IDSRV_LOGIN : window.__env__.REACT_APP_IDSRV_LOGIN,
	clientSecret: dev || test ? process.env.REACT_APP_IDSRV_CLIENT_SECRET : window.__env__.REACT_APP_IDSRV_CLIENT_SECRET,
}
