export const ThemeColors = {
	WHITE: '#FFFFFF',
	BLACK_ALPHA900: 'blackAlpha.800',
	LIGHT_GRAY: '#34343433',
	LIGHTER_GRAY: '#FFFFFF0d',
	GRAY_800: '#27272a',
	GRAY_200: '#e4e4e7',
	GRAY_300: '#898989',
	GRAY_900: '#18181b',
	GRAY_950: '#111111',
	GRAY_1000: '#343434',
	BACKGROUND_PRIMARY_LIGHT: '#F6F6F6',
	BACKGROUND_PRIMARY_DARK: '#242424',
}
