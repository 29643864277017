import { Box, IconButton, Image, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { ThemeColors } from 'enums'
import { ChevronLeftShortIcon, ChevronRightShortIcon, SolaborateLongIcon } from 'icons/global'
import GlobalSearch from 'layout/GlobalSearch'
import { newTabs } from 'layout/routeTabs'
import { SidebarFooter } from 'layout/SidebarFooter'
import SidebarList from 'layout/SideBarList'
import { useAuth } from 'oidc-react'
import { useEffect, useMemo, useState } from 'react'

const solaborateLogo = `${process.env.REACT_APP_CDN_URL}global/brand/assets/logos/solaborate-s-only-logo.png`

const Sidebar = () => {
	const { colorMode } = useColorMode()
	const {
		userData: { profile },
	} = useAuth()
	const [isExpanded, setIsExpanded] = useState(true)

	const authorizedRoutes = useMemo(() => {
		return newTabs.filter((item) =>
			item.roles.some((item) => (profile['sub.role'] ? profile['sub.role'].includes(item) : profile.role?.includes(item)))
		)
	}, [])

	const handleIsExpanded = () => setIsExpanded((prev) => !prev)

	const sidebarBackground = useColorModeValue(ThemeColors.LIGHT_MODE.WHITE, ThemeColors.LIGHT_MODE.BLACK)
	const borderColor = useColorModeValue(ThemeColors.LIGHT_MODE.DARK_GRAY, ThemeColors.LIGHT_MODE.DARK_BLACK)

	useEffect(() => {
		const onKeyPress = (e) => {
			if (e.key === ' ' && e.ctrlKey) {
				handleIsExpanded()
			}
		}

		document.addEventListener('keypress', onKeyPress)

		return () => {
			document.removeEventListener('keypress', onKeyPress)
		}
	}, [])

	const isDarkMode = colorMode === 'dark'

	return (
		<Stack borderRight={`1px solid ${borderColor}`} bg={sidebarBackground} height='100vh' position='relative' spacing={8}>
			<Stack p='4' spacing='4' alignItems={isExpanded ? 'flex-start' : 'center'}>
				<IconButton
					onClick={handleIsExpanded}
					icon={isExpanded ? <ChevronLeftShortIcon boxSize='4' /> : <ChevronRightShortIcon boxSize='4' />}
					size='xss'
					position='absolute'
					right={-2}
					top={5}
					rounded='full'
				/>
				{isExpanded ? <SolaborateLongIcon isDarkMode={isDarkMode} /> : <Image width={25} height={25} src={solaborateLogo} />}
				<GlobalSearch isExpanded={isExpanded} />
			</Stack>
			<Box flexGrow='1' overflow='auto'>
				<SidebarList tabs={authorizedRoutes} isExpanded={isExpanded} />
			</Box>
			<SidebarFooter isExpanded={isExpanded} />
		</Stack>
	)
}

export default Sidebar
