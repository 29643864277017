import { Box, Flex, Text } from '@chakra-ui/react'
import { calculatePercentage } from 'helpers'

const lightGreen = '#198754'
const darkGreen = '#1B4632'

export const ProgressBar = ({ items, totalItems, statusColors, height, showLabels, stripedStatus }) => {
	const stripedBackgroundColor = (row) =>
		row === stripedStatus
			? `linear-gradient(-45deg, ${darkGreen} 25%, ${lightGreen} 25%, ${lightGreen} 50%, ${darkGreen} 50%, ${darkGreen} 75%, ${lightGreen} 75%, ${lightGreen} 100%)`
			: 'none'

	return (
		<Flex width='full' rounded='xl' height={height || '10'} overflow='hidden'>
			{items.map((item, index) => (
				<Box
					key={index}
					width={`${calculatePercentage(item.value, totalItems)}%`}
					backgroundImage={stripedBackgroundColor(item.label)}
					backgroundSize='8.49px 8.49px'
					backgroundColor={statusColors[index]}
					position='relative'
					title={`${item.label}: ${item.value} items (${calculatePercentage(item.value, totalItems).toFixed(2)}%)`}>
					{console.log(item)}
					{showLabels && calculatePercentage(item.value, totalItems) > 12 && (
						<Text
							position='absolute'
							top='50%'
							left='50%'
							transform='translate(-50%, -50%)'
							fontSize='small'
							fontWeight='500'
							color='blackAlpha.800'
							whiteSpace='nowrap'>
							{item.label} ({calculatePercentage(item.value, totalItems).toFixed(1)}%)
						</Text>
					)}
				</Box>
			))}
		</Flex>
	)
}
