import { IconButton, Input, InputGroup, InputLeftElement, useDisclosure } from '@chakra-ui/react'
import { GlobalSearchModal } from 'components'
import { SearchIcon } from 'icons/global'

const GlobalSearch = ({ isExpanded }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			{isExpanded && (
				<InputGroup onClick={onOpen} size='sm' variant='filled'>
					<Input cursor='pointer' placeholder='Search' isReadOnly rounded='lg' />
					<InputLeftElement>
						<SearchIcon />
					</InputLeftElement>
				</InputGroup>
			)}
			{!isExpanded && <IconButton icon={<SearchIcon />} onClick={onOpen} size='sm' rounded='lg' />}
			{isOpen && <GlobalSearchModal isOpen={isOpen} onClose={onClose} />}
		</>
	)
}

export default GlobalSearch
